(function ($) {
     'use strict';
     $.fn.tabNavigation = function (config) {
         var options = $.extend({
             content     : 'div',       // 表示・非表示対象とするコンテンツ
             headline    : '.nav-list', // ナビゲーションにする要素
             open        : 1,           // 最初にオープン状態にするコンテンツのIndex番号
             navClass    : 'tab-nav',   // ナビゲーションのClass名
             activeClass : 'active',    // オープン状態のナビ、コンテンツに付けられるClass名
             duration    : 'normal'     // タブ切替時のアニメーションの速度
         }, config);

         this.each(function () {
             var contents = $(this).find(options.content),
                 nav      = $('<div></div>', { 'class': options.navClass });

             contents.each(function (i) {
                 var headline = $(this).find(options.headline);
                 headline.addClass('nav-ctrl-' + i).data('index', i);
                 if (i === options.open) {
                     $(this).addClass(options.activeClass);
                     headline.addClass(options.activeClass);
                 } else {
                     $(this).hide();
                 }
             });

             nav.prepend(contents.find(options.headline));
             nav.find(options.headline).on('click', function (e) {
                // console.log($(this).index());
                 if ($(this).index() !== undefined) {
                     options.open = $(this).index();
                     nav.find(options.headline).each(function (i) {
                         if (i === options.open) {
                             $(this).addClass(options.activeClass);
                         } else {
                             $(this).removeClass(options.activeClass);
                         }
                     });

                     contents.each(function (i) {
                         if (i === options.open) {
                             $(this).fadeIn(options.duration);
                             $(this).addClass(options.activeClass);
                         } else {
                             $(this).hide().removeClass(options.activeClass);
                         }
                     });
                 }
             });
             $(this).prepend(nav);
         });
         return this;
     };
 }(jQuery));
