import '../../plugins/jquery.tabnavigation.js';

$('.js-tab').tabNavigation({
  headline: '.c-tab__label',
  content: '.c-tab__item',
  navClass: 'c-tab__navi',
  open: 0
});

$('.js-tab-side').tabNavigation({
  headline: '.c-tab-side__label',
  content: '.c-tab-side__item',
  navClass: 'c-tab-side__navi',
  open: 0
});
